<template>
  <q-layout view="hHh LpR fff">
    <q-header elevated>
      <q-toolbar>
        <q-btn
          :to="`/${$route.params.locale}`"
          flat
          label="FSE-FBO5"
          stretch
        />
        <q-space/>
        <template v-if="$_.get(data, 'id')">
          <q-btn
            :to="`/${$route.params.locale}/dashboard/group`"
            flat
            label="Groups"
            stretch
          />
          <q-btn-dropdown
            flat
            icon="fa fa-user"
            stretch
          >
            <q-list>
              <q-item
                v-close-popup
                :to="`/${$route.params.locale}/sign-out`"
                clickable
              >
                <q-item-section>
                  <q-item-label
                    class="text-uppercase"
                  >
                    Sign out
                  </q-item-label>
                </q-item-section>
              </q-item>
            </q-list>
          </q-btn-dropdown>
        </template>
        <template v-else>
          <q-btn
            :to="`/${$route.params.locale}/sign-up`"
            flat
            label="Sign up"
            stretch
          />
          <q-btn
            :to="`/${$route.params.locale}/sign-in`"
            flat
            label="Sign in"
            stretch
          />
        </template>
      </q-toolbar>
    </q-header>
    <q-page-container>
      <q-page>
        <router-view/>
      </q-page>
    </q-page-container>
    <q-footer>
      <app-footer/>
    </q-footer>
  </q-layout>
</template>

<script>
  import AppFooter from 'components/AppFooter'
  import { createNamespacedHelpers } from 'vuex'

  const moduleName = 'auth'
  const { mapActions, mapState } = createNamespacedHelpers(
    moduleName,
  )

  export default {
    components: {
      AppFooter,
    },
    computed: {
      ...mapState(
        [
          'data',
        ],
      ),
    },
    meta: {
      meta: {
        charset: {
          charset: 'utf-8',
        },
        description: {
          content: 'FSE-FBO5 makes you the best FBO boss',
          name: 'description',
        },
        equiv: {
          content: 'text/html; charset=UTF-8',
          'http-equiv': 'Content-Type',
        },
        msapplicationTileColor: {
          content: '#008000',
          name: 'msapplication-TileColor',
        },
        msapplicationTileImage: {
          content: '/ms-icon-144x144.png',
          name: 'msapplication-TileImage',
        },
        ogTitle: {
          name: 'og:title',
          template (ogTitle) {
            return (ogTitle ? `${ogTitle} | ` : '') + 'FSE-FBO5'
          },
        },
        themeColor: {
          content: '#008000',
          name: 'theme-color',
        },
        viewport: {
          content: 'width=device-width, initial-scale=1',
          name: 'viewport',
        },
      },
      link: {
        appleTouchIcon57: {
          href: '/apple-icon-57x57.png',
          rel: 'apple-touch-icon',
          sizes: '57x57',
        },
        appleTouchIcon60: {
          href: '/apple-icon-60x60.png',
          rel: 'apple-touch-icon',
          sizes: '60x60',
        },
        appleTouchIcon72: {
          href: '/apple-icon-72x72.png',
          rel: 'apple-touch-icon',
          sizes: '72x72',
        },
        appleTouchIcon76: {
          href: '/apple-icon-76x76.png',
          rel: 'apple-touch-icon',
          sizes: '76x76',
        },
        appleTouchIcon114: {
          href: '/apple-icon-114x114.png',
          rel: 'apple-touch-icon',
          sizes: '114x114',
        },
        appleTouchIcon120: {
          href: '/apple-icon-120x120.png',
          rel: 'apple-touch-icon',
          sizes: '120x120',
        },
        appleTouchIcon144: {
          href: '/apple-icon-144x144.png',
          rel: 'apple-touch-icon',
          sizes: '144x144',
        },
        appleTouchIcon152: {
          href: '/apple-icon-152x152.png',
          rel: 'apple-touch-icon',
          sizes: '152x152',
        },
        appleTouchIcon180: {
          href: '/apple-icon-180x180.png',
          rel: 'apple-touch-icon',
          sizes: '180x180',
        },
        icon: {
          href: 'favicon.ico',
          type: 'image/ico',
          rel: 'icon',
        },
        icon16: {
          href: '/favicon-16x16.png',
          type: 'image/png',
          rel: 'icon',
          sizes: '16x16',
        },
        icon32: {
          href: '/favicon-32x32.png',
          type: 'image/png',
          rel: 'icon',
          sizes: '32x32',
        },
        icon96: {
          href: '/favicon-96x96.png',
          type: 'image/png',
          rel: 'icon',
          sizes: '96x96',
        },
        icon192: {
          href: '/android-icon-192x192.png',
          type: 'image/png',
          rel: 'icon',
          sizes: '96x96',
        },
        manifest: {
          href: '/manifest.json',
          rel: 'manifest',
        },
      },
      title: 'FSE-FBO5',
      titleTemplate: title => 'FSE-FBO5' === title
        ? title
        : `${title} | FSE-FBO5`,
    },
  }
</script>
